import Layout from "@/src/layout/Layout";
import { sliderProps } from "@/src/sliderProps";
import { Fragment, useState, useEffect } from "react";
import { client, urlFor } from "../lib/client";
import { NextSeo, ArticleJsonLd } from "next-seo";
 
import {

  AboutSection,
} from "@/src/components/home-page";

import dynamic from "next/dynamic";
import FirstPaint from "./homepage/FirstPaint";
const HeroSection = dynamic(() => import('@/src/components/home-page/HeroSection'), {ssr: true});

const WhatWeDoSection = dynamic(() => import('@/src/components/home-page/WhatWeDoSection'));
const StatisticSection = dynamic(() => import('@/src/components/home-page/StatisticSection'));

const PopularSection = dynamic(() => import('@/src/components/home-page/PopularSection'));
const GallerySection = dynamic(() => import('@/src/components/home-page/GallerySection'));
const TeamMember = dynamic(() => import('@/src/components/home-page/TeamMember'));
const WhyChooseUs = dynamic(() => import('@/src/components/home-page/WhyChooseUs'));
const BlogSection = dynamic(() => import('@/src/components/home-page/BlogSection'));
const HeadLine = dynamic(() => import('@/src/components/home-page/HeadLine'));
const Testimonials = dynamic(() => import('@/src/components/home-page/Testimonials'));

// import { createRedisInstance } from '../redis'

const SSRPageHome = ({
  heroSection,
  aboutSectionData,
  whatWeDoSection,
  statisticSectionData,
  popularSectionData,
  gallerySectionData,
  teamSectionData,
  whyChooseUsSectionData,
  testimonialsSectionData,
  featuredBlogSectionData,
  fetchFooter,
  fetchHeader,
  fHeaderMenuData,
  seo,
}) => {

  const [firstPaint, setFirstPaint] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setFirstPaint(true);
      window.removeEventListener('scroll', handleScroll);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  // about-section data here
  const {
    AboutImageData,
    about_button_link,
    aboutSectionHeading,
    aboutSectionMainHeading,
    aboutSectionParagraph,
    aboutSectionHeadLine,
  } = aboutSectionData;
  // what we do section data here
  const {
    WhatWeDoSectionHeading,
    WhatWeDoSectionMainHeading,
    WhatWeDoImageData,
    WhatWeDoServiceItems,
  } = whatWeDoSection;
  //  Popular-section data
  const {
    popularSectionHeadLine,
    popularSectionMainHeading,
    popularSectionheading,
    transformedPopularSectionServicesItems,
  } = popularSectionData;
  // team-member data here
  const {
    teamSectionheading,
    teamSectionMainHeading,
    teamSectionMemberData,
  } = teamSectionData;
  // pricing section data
  // why-choose-us section data
  const {
    whyChooseSectionHeadLine,
    whyChooseSectionHeading,
    whyChooseSectionHeroImageData,
    whyChooseSectionMainHeading,
    whyChooseSectionItems,
  } = whyChooseUsSectionData;
  // testimonials-section data
  const { testimonialsSectionItems, t_hero_image_d } = testimonialsSectionData;
  // featuredBlog-section
  const {
    featuredBlogSectionFeaturedPosts,
    featuredBlogSectionHeading,
    featuredBlogSectionMainHeading,
  } = featuredBlogSectionData;





  return (
    <Fragment>
      <Layout>
        <FirstPaint />

        {firstPaint &&
            (
              <>

        <AboutSection
          AboutImageData={AboutImageData}
          aboutSectionHeading={aboutSectionHeading}
          aboutSectionMainHeading={aboutSectionMainHeading}
          aboutSectionParagraph={aboutSectionParagraph}
          about_button_link={about_button_link}
        />
        {/* About Us Area end */}

        {/* Headline area start */}
        
        <HeadLine data={aboutSectionHeadLine} />

        {/* Headline Area end */}
        {/* What We Do start */}

        <WhatWeDoSection
          WhatWeDoSectionHeading={WhatWeDoSectionHeading}
          WhatWeDoSectionMainHeading={WhatWeDoSectionMainHeading}
          WhatWeDoServiceItems={WhatWeDoServiceItems}
          WhatWeDoImageData={WhatWeDoImageData}
        />
        {/* What We Do end */}
        {/* Statistics Area start */}
        <StatisticSection statisticSectionData={statisticSectionData} />

        {/* Statistics Area end */}
        {/* Service Style Three start */}
        <PopularSection
          popularSectionheading={popularSectionheading}
          popularSectionMainHeading={popularSectionMainHeading}
          transformedPopularSectionServicesItems={
            transformedPopularSectionServicesItems
          }
        />
 

        {/* Service Style Three end */}
        {/* Headline area start */}
        <HeadLine data={popularSectionHeadLine.html} />

        {/* Headline Area end */}
        {/* Project Timeline Area start */}
        <GallerySection gallerySectionData={gallerySectionData} />

        {/* Project Timeline Area end */}
        {/* Team Area start */}
        <TeamMember
          teamSectionheading={teamSectionheading}
          teamSectionMainHeading={teamSectionMainHeading}
          teamSectionMemberData={teamSectionMemberData}
        />
        {/* Team Area end */}
        {/* Headline area start */}

        {/* Headline Area end */}
        {/* Pricing style three start */}

        {/* Pricing style three end */}
        {/* Why Choose Us Area start */}
        <WhyChooseUs
          whyChooseSectionHeroImageData={whyChooseSectionHeroImageData}
          whyChooseSectionHeading={whyChooseSectionHeading}
          whyChooseSectionMainHeading={whyChooseSectionMainHeading}
          whyChooseSectionItems={whyChooseSectionItems}
        />
        {/* Why Choose Us Area end */}
        {/* Headline area start */}
        <HeadLine data={whyChooseSectionHeadLine.html} />

        {/* Headline Area end */}
        {/* Testimonial Area start */}
        <Testimonials
          sliderProps={sliderProps}
          testimonialsSectionItems={testimonialsSectionItems}
          t_hero_image_d={t_hero_image_d}
        />

        {/* Testimonial Area end */}
        {/* Blog Style Two start */}

        <BlogSection
          featuredBlogSectionHeading={featuredBlogSectionHeading}
          featuredBlogSectionMainHeading={featuredBlogSectionMainHeading}
          featuredBlogSectionFeaturedPosts={featuredBlogSectionFeaturedPosts}
        />
        {/* Blog Style Two end */}
        {/* footer area start */}

              </>

            )
        }



      </Layout>
    </Fragment>
  );
};



export default SSRPageHome;






// **** getting page data **** //
// **** getting page data **** //
// **** getting page data **** //
// **** getting page data **** //
// **** getting page data **** //
// **** getting page data **** //
// **** getting page data **** //
// **** getting page data **** //
// **** getting page data **** //
// **** getting page data **** //
// **** getting page data **** //
// **** getting page data **** //
// **** getting page data **** //
// **** getting page data **** //
// **** getting page data **** //


export async function getStaticProps() {

  // context.res.setHeader('Cache-Control', 'public, max-age=300'); 
  // Cache the page for 1 hour
  const query = `*[ _type == "page" && title == "Homepage" ] {
    ...,
    _id,
    _type,
 "teamMemberData": pageBuilder.teamMember.teamMembers[]->{
      title,
      designation,
      slug,
      member_image,
      socials,
    },
    "featuredPosts": pageBuilder.FeaturedBlog.featuredPosts[]->{
      _id,
      title,
      content,
      mainImage,
      publishedAt,
      slug,
      seo,
      author
    }
  }[0]
`;

  const homePage = await client.fetch(query);

  const { seo, pageBuilder, featuredPosts, teamMemberData } = homePage;

  const {
    hero,
    aboutSection,
    what_we_do,
    statistic,
    popular,
    teamMember,
    pricingPackage,
    whyChooseUs,
    testimonials,
    FeaturedBlog,
  } = pageBuilder;

  // ********* ------ hero-section Starts here ------  ********* //
  const { rawHtmlContent, hero_image, socials, hero_image_mobile } = hero;
  const mainHeroImageConverted = urlFor(hero_image).url();
  const mainHeroImageMobile = urlFor(hero_image_mobile).url();
  const mainHeroImageData = {
    imageUrl: mainHeroImageConverted,
    alt: hero_image.alt,
    mobileImage: mainHeroImageMobile,
  };
  // hero-section links destructure
  const socialsBuilderObject = {};
  socials.forEach((section) => {
    socialsBuilderObject[section.platform] = section;
  });
  const heroSection = {
    rawHtmlContent,
    mainHeroImageData,
    socialsBuilderObject,
  };
  // ********* ------ hero-section ends here ------  ********* //

  // *********  <------ About section Starts here ------>  *********  //

  const { about_button_link, left_side_cicle_image } = aboutSection;
  // building about section image URL
  const AboutImageConverted = urlFor(left_side_cicle_image).url();
  const AboutImageData = {
    imageUrl: AboutImageConverted,
    alt: left_side_cicle_image.alt,
  };
  // destructuring and changing name so it dont get mixed up with other components
  const aboutSectionHeading = aboutSection.heading;
  const aboutSectionMainHeading = aboutSection.main_Heading;
  const aboutSectionParagraph = aboutSection.paragraph;
  const aboutSectionHeadLine = aboutSection.headLine.rawHtmlContent.html;
  const aboutSectionData = {
    AboutImageData,
    about_button_link,
    aboutSectionHeading,
    aboutSectionMainHeading,
    aboutSectionParagraph,
    aboutSectionHeadLine,
  };
  // *********  <------ About section ends here ------>  *********  //

  // *********  <------ what we do section starts here ------>  *********  //
  const WhatWeDoSectionHeading = what_we_do.heading;
  const WhatWeDoSectionMainHeading = what_we_do.main_Heading;
  const WhatWeDoRightImage = what_we_do.right_side_cicle_image;
  const WhatWeDoServiceItems = what_we_do.services_items;
  // building Image url for what we do section
  const WhatWeDoImageConverted = urlFor(WhatWeDoRightImage).url();
  const WhatWeDoImageData = {
    imageUrl: WhatWeDoImageConverted,
    alt: WhatWeDoRightImage.alt,
  };
  const whatWeDoSection = {
    WhatWeDoSectionHeading,
    WhatWeDoSectionMainHeading,
    WhatWeDoImageData,
    WhatWeDoServiceItems,
  };
  // *********  <------ what we do section ends here ------>  *********  //

  // *********  <------   statistic section starts here  ------>  *********  //
  const statisticSectionData = statistic.statistics;
  // *********  <------ statistic section starts here ------>  *********  //

  // *********  <------   Popular-services section starts here  ------>  *********  //
  const popularSectionHeadLine = popular.headLine.rawHtmlContent;
  const popularSectionheading = popular.heading;
  const popularSectionMainHeading = popular.main_Heading;
  const popularSectionServicesItems = popular.services_items;

  const transformData = (data) => {
    return data.map((item) => {
      const imageUrlConverted = urlFor(item.service_image).url();
      return {
        ...item,
        image: {
          url: imageUrlConverted,
          alt: item.service_image.alt,
        },
      };
    });
  };

  const transformedPopularSectionServicesItems = transformData(
    popularSectionServicesItems
  );

  const popularSectionData = {
    popularSectionHeadLine,
    popularSectionheading,
    popularSectionMainHeading,
    transformedPopularSectionServicesItems,
  };

  // *********  <------ Popular-services section end here ------>  *********  //

  // *********  <------ Gallery section ------>  *********  //



  // *********  <------ Gallery section end ------>  *********  //

  const transformDataTeamMember = (data) => {
    return data.map((item) => {
      const imageUrlConverted = urlFor(item.member_image).url();
      return {
        ...item,
        image: {
          url: imageUrlConverted,
          alt: item.member_image.alt,
        },
      };
    });
  };
  // *********  <------ teamMember section ------>  *********  //
  const teamSectionheading = teamMember.heading;
  const teamSectionMainHeading = teamMember.main_Heading;
  const teamSectionServicesItems = teamMember.services_items;
  const transformedTeamSectionServicesItems = transformData(
    teamSectionServicesItems
  );
  const teamSectionHeadline = teamMember.headLine.rawHtmlContent;
  const teamSectionMemberData = transformDataTeamMember(teamMemberData);
  const teamSectionData = {
    teamSectionheading,
    teamSectionMainHeading,
    transformedTeamSectionServicesItems,
    teamSectionHeadline,
    teamSectionMemberData,
  };

  // *********  <------ Gallery section end ------> *********  //

  const transformDataBg = (data) => {
    const transformData = data.background_image;
    const convertImage = urlFor(transformData).url();
    const convertedImageData = {
      image: { alt: transformData.alt, img: convertImage },
    };
    const convertedData = { ...data, convertedImageData };
    return convertedData;
  };

  // *********  <------ pricing section ------>  *********  //

  const pricingSectionHeading = pricingPackage.heading;
  const pricingSectionMainHeading = pricingPackage.main_Heading;

  const pricingSectionFirstObject = pricingPackage.hero_object_pricing;
  const pricingSectionSecondObject = pricingPackage.monthly_package;
  const pricingSectionThirdObject = pricingPackage.yearly_package;

  const pricingSectionTransformFirstObject = transformDataBg(
    pricingSectionFirstObject
  );
  const pricingSectionTransformSecondObject = transformDataBg(
    pricingSectionSecondObject
  );
  const pricingSectionTransformThirdObject = transformDataBg(
    pricingSectionThirdObject
  );

  const pricingSectionData = {
    pricingSectionHeading,
    pricingSectionMainHeading,
    pricingSectionTransformFirstObject,
    pricingSectionTransformSecondObject,
    pricingSectionTransformThirdObject,
  };
  // transform data structure for urls

  // *********  <------ pricing section end ------> *********  //

  // *********  <------ why-choose-us section ------>  *********  //
  const whyChooseSectionHeading = whyChooseUs.heading;
  const whyChooseSectionMainHeading = whyChooseUs.main_Heading;
  const whyChooseSectionHeroImage = urlFor(whyChooseUs.left_side_image).url();
  const whyChooseSectionHeroImageData = {
    image: whyChooseSectionHeroImage,
    alt: whyChooseUs.left_side_image.alt,
  };
  const whyChooseSectionHeadLine = whyChooseUs.headLine.rawHtmlContent;
  const whyChooseSectionItems = whyChooseUs.services_items;

  const whyChooseUsSectionData = {
    whyChooseSectionHeading,
    whyChooseSectionMainHeading,
    whyChooseSectionHeroImageData,
    whyChooseSectionHeadLine,
    whyChooseSectionItems,
  };
  // *********  <------ end why-choose-us  section ------>  *********  //

  const transformDataTestimonials = (data) => {
    return data.map((item) => {
      const imageUrlConverted = urlFor(item.right_side_image).url();
      return {
        ...item,
        image: {
          url: imageUrlConverted,
          alt: item.right_side_image.alt,
        },
      };
    });
  };

  // *********  <------ testimonials section ------>  *********  //

  const testimonialsSectionItems = transformDataTestimonials(
    testimonials.services_items
  );

  const t_hero_image = urlFor(testimonials.t_hero_img).url();
  const t_hero_alt = testimonials.t_hero_img.alt;
  const t_hero_image_d = {
    image: t_hero_image,
    alt: t_hero_alt,
  };

  const testimonialsSectionData = { testimonialsSectionItems, t_hero_image_d };

  // *********  <------ end testimonials section ------>  *********  //

  // *********  <------ featured-blogs section ------>  *********  //

  const featuredBlogSectionHeading = FeaturedBlog.heading;
  const featuredBlogSectionMainHeading = FeaturedBlog.main_Heading;
  const featuredBlogSectionFeaturedPosts = featuredPosts;

  const featuredBlogSectionData = {
    featuredBlogSectionHeading,
    featuredBlogSectionMainHeading,
    featuredBlogSectionFeaturedPosts,
  };

  // *********  <------ featured-blogs section ------>  *********  //



  return {
    props: {
      homePage,
      hero,
      heroSection,
      aboutSectionData,
      whatWeDoSection,
      statisticSectionData,
      popularSectionData,
      gallerySectionData: pageBuilder.recentGallery,
      teamSectionData,
      pricingSectionData,
      whyChooseUsSectionData,
      testimonialsSectionData,
      featuredBlogSectionData,
      teamMemberData,
      seo,
    },
  };
};
//  }