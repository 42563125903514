import { Fragment} from "react";
import herodata from '../../src/data/herodata.json'
import seodata from '../../src/data/seodata.json'

import HeroSection from '../../src/components/home-page/HeroSection'
import { NextSeo, ArticleJsonLd } from "next-seo";
import { urlFor } from "@/lib/client";

const FirstPaint = ({
}) => {

  //  hero-section data here

const { rawHtmlContent, mainHeroImageData, socialsBuilderObject } = herodata;

const seo = seodata.seo;

const {
  metaTitle,
  metaDescription,
  canonical,
  jsonLtd,
  type,
  url,
  seo_image,
} = seo;

// Trigger the loading of the Layout component after the initial render


  return (
    <Fragment>
     
         <NextSeo
        title={metaTitle}
        description={metaDescription}
        canonical={canonical}
        robots="index, follow"
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/assets/images/favicon.png',
          },
          {
            rel: 'apple-touch-icon',
            href: 'https://www.test.ie/touch-icon-ipad.jpg',
            sizes: '76x76'
          },
         ]}
        openGraph={{
          type: type,
          url: url,
          title: metaTitle,
          description: metaDescription,
          image: urlFor(seo_image).url(),
        }}
      />
      <ArticleJsonLd dataArray={JSON?.parse(jsonLtd)} />
        <HeroSection
          rawHtmlContent={rawHtmlContent}
          mainHeroImageData={mainHeroImageData}
          socialsBuilderObject={socialsBuilderObject}
        />

    </Fragment>
  );
};



export default FirstPaint;

